import React from 'react'

import { useState } from "react";
import style from "../CSS/Style.module.css";

import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
export default function ProjectSucessPage() {
  return (
    <>
      
      <body>
      <Header />
      <div className={style.main_content}>
          <div className={style.breadcrumps}>
            <ul className={style.breadcrumpsItems}>
              <li>
                <Link to="/dashboard">Home </Link>
              </li>

              <li>
            
                <Link to="/addclient">Add Client</Link>
              </li>
              
            </ul>
          </div> 
          <div className={style.contentwrpper}>
            <h2>
              <center>Project details added successfully </center>
            </h2>
            <div>
            <Link to="/addproject">
        <button>Add Project</button>
      </Link>
      <Link to="/addclient">
        <button>Add Client</button>
      </Link>
      <Link to="/invoice">
        <button>Create Invoice</button>
      </Link>
            </div>
            </div>
               
          
          </div>
      </body>
      </>
  )
}
