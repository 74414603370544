import React, { useState, useEffect } from 'react';
import { Navigate,Link } from 'react-router-dom';
import Header from './Header';
import InvoiceDetailsModal from './InvoiceDetailsModal';
import DatePicker from 'react-datepicker';
import style from '../CSS/Style.module.css';
import Footer from './Footer';
import axios from 'axios';
//import { useAuth } from './Auth';
// const { isLoggedIn } = useAuth();

//   if (!isLoggedIn) {
//     return <Navigate to="/" />;
//   }
export default function Dashboard({isLoggedIn}) {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const [invoicedetails, setInvoiceDetails] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterApplied, setFilterApplied] = useState(false);
  const [searchApplied, setSearchApplied] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [downloaded, setDownloaded] = useState(false);

  //const { isLoggedIn } = useAuth();

  // if (!isLoggedIn) {
  //   return <Navigate to="/" />;
  // }

  const handleDatePicker = () => {
    setShowDatePicker(true);
  };

  const handlestartDatePicker = (event) => {
    setSelectedStartDate(event);
  };

  const handleendDatePicker = (event) => {
    setSelectedEndDate(event);
  };

  useEffect(() => {
    if (filterApplied) {
      fetchInvoices();
      setFilterApplied(false); // Reset the filterApplied state after fetching
    }
  }, [selectedStartDate, selectedEndDate, filterApplied]);

  useEffect(() => {
    fetchRecentInvoices();
  }, []);

  useEffect(() => {
    if (searchApplied) {
      fetchSearchResult();
      setSearchApplied(false); // Reset the searchApplied state after fetching
    }
  }, [searchQuery, searchApplied]);

  useEffect(() => {
    console.log('Modal state:', modalOpen);
  }, [modalOpen]);

  const fetchRecentInvoices = async () => {
    try {
      const response = await fetch(`${apiUrl}/fetchRecentInvoice`);
      const data = await response.json();
      const formattedInvoice = data.map((invoice) => ({
        ...invoice,
        issueDate: new Date(invoice.issueDate).toLocaleDateString(),
        dueDate: new Date(invoice.dueDate).toLocaleDateString(),
      }));
      setInvoiceDetails(formattedInvoice);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchInvoices = async () => {
    try {
      const response = await fetch(`${apiUrl}/filterinvoices?startDate=${selectedStartDate}&endDate=${selectedEndDate}`);
      const data = await response.json();
      setInvoiceDetails(data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  const handleDateFilter = () => {
    if (selectedStartDate && selectedEndDate) {
      setFilterApplied(true);
    }
  };

  const fetchSearchResult = async () => {
    try {
      const response = await fetch(`${apiUrl}/invoiceSearch?query=${searchQuery}`);
      const data = await response.json();
      const formattedInvoice = data.map((invoice) => ({
        ...invoice,
        issueDate: new Date(invoice.issueDate).toLocaleDateString(),
        dueDate: new Date(invoice.dueDate).toLocaleDateString(),
      }));
      setInvoiceDetails(formattedInvoice);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  const handleViewClick = async (invoiceId) => {
    try {
      const response = await fetch(`${apiUrl}/viewInvoice/${invoiceId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch invoice details');
      }
      const data = await response.json();
      setInvoiceData(data);
      setModalOpen(true);
    } catch (error) {
      console.error('Error fetching invoice details:', error);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalDownload = (invoiceId) => {
    axios.get(`${apiUrl}/api/invoiceDownload/${invoiceId}`, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'latest_invoice.pdf');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setDownloaded(true);
      })
      .catch((error) => {
        console.error('Error downloading latest invoice:', error);
      });
  };


  return (
    // <>
    //  {
    //  isLoggedIn ? (
     <>
    <Header /><body>
            <div className={style.main_content}>
              <div className={style.breadcrumps}>
                <ul className={style.breadcrumpsItems}>
                  <li>
                    <Link to='/dashboard'>Home </Link></li>

                  <li> <Link to='/dashboard'>Dashboard</Link></li></ul>
              </div>
              <div className={style.contentwrpper}>
                <h2><center>Dash board Page</center></h2>

                <div className={style.searchsection}>
                  {/* <div className={style.searchdropdown}>
    <select name="searchByField" id="searchByField">
<option value="invoiceNumber">Invoice Number</option>
<option value="clientName">Client Name</option>
<option value="emailId">Email Id</option>

</select>
    </div> */}
                  <div className={style.searchfiels}>
                    <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder='Search...' /><button onClick={fetchSearchResult}>
                      <i class={`fa fa-search ${style.searchicon}`}
                      >
                      </i>
                    </button>
                  </div>
                  <div className={style.filter}>
                    <button onClick={handleDatePicker}>Filter by Date</button>
                  </div>
                  {showDatePicker && (
                    <div>
                      <label>Start Date:</label>


                      <DatePicker name="sdate" selected={selectedStartDate} onChange={handlestartDatePicker} dateFormat="dd/MM/yyyy" />



                      <label>End Date:</label>
                      <DatePicker name="edate" selected={selectedEndDate} onChange={handleendDatePicker} dateFormat="dd/MM/yyyy" />

                      <button onClick={handleDateFilter}>Apply Filter</button>
                    </div>
                  )}

                </div>
                <div className='table-container'>
                  {invoicedetails.length ? (
                    <table>
                      <thead>
                        <tr>

                          <th>Invoice Number</th>
                          <th>Client Name</th>
                          <th>Issue Date</th>
                          <th>Due Date</th>
                          <th>Amount</th>

                        </tr>
                      </thead>
                      <tbody>
                        {invoicedetails.map((client) => (
                          <tr key={client.invoiceId}>
                            <td>{client.invoiceNumber}</td>
                            <td>{client.client_name}</td>
                            <td>{client.issueDate}</td>
                            <td>{client.dueDate}</td>
                            <td>{client.currency}{client.totalAmount}</td>
                            <td><button onClick={() => handleViewClick(client.invoiceId)}>View Invoice</button></td>
                          </tr>

                        ))}
                      </tbody> </table>) : <p>no data found</p>}
                  {/* Render the InvoiceDetailsModal component when the modal is open */}
                  {modalOpen && (
                    <InvoiceDetailsModal
                      invoiceData={invoiceData}
                      onClose={handleModalClose}
                      onDownload={handleModalDownload} />
                  )}
                </div>
              </div></div></body><Footer />
              </>
             //  ) : (<Link to='/'>Login</Link>) }</>
   
  )
}
