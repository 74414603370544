function validateForm (userData)  {
    //let valid = true;
    const newErrors = {};

    // Validate firstname
    if (!userData.firstname.trim()) {
      newErrors.firstname = "Firstname is required";
     // valid = false;
    }
    else{
      newErrors.firstname=""
  }
    // Validate surname
    if (!userData.surname.trim()) {
      newErrors.surname = "Surname is required";
      
    }
    else{
      newErrors.surname=""
  }

    // Validate email
    if (!userData.email.trim()) {
      newErrors.email = "Email is required";
      //valid = false;
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      newErrors.email = "Email is invalid";
      //valid = false;
    }
    else{
      newErrors.email=""
  }
    // Validate phone number
    if (!userData.phoneNo.trim()) {
      newErrors.phoneNo = "Phone number is required";
     // valid = false;
    } else if (!/^\d{10}$/.test(userData.phoneNo)) {
      newErrors.phoneNo = "Phone number must be 10 digits";
      //valid = false;
    }
    else{
      newErrors.phoneNo=""
  }
    // Validate password

    if (!userData.password.trim()) {
        newErrors.password = "Password is required";
        //valid = false;
      } else if (! /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(userData.password)) {
        newErrors.password = "password should contain atlease one number,uppercase,special symbol and min 8 char";
       // valid = false;
      }
      else{
        newErrors.password=""
    }
    // Validate date of birth
    if (!userData.dob.date || !userData.dob.month || !userData.dob.year) {
      newErrors.dob = "Date of Birth is required";
     // valid = false;
    }else{
      newErrors.dob=""
  }
    // Validate gender
    if (!userData.gender) {
      newErrors.gender = "Gender is required";
     // valid = false;
    }
    else{
      newErrors.gender=""
  }
  return newErrors; 
  };
export default validateForm;