import React, { useEffect, useState } from 'react'
import style from '../CSS/Style.module.css'
import Header from './Header';
import { Link, useNavigate } from "react-router-dom";
import Validation from './LoginValidation';
//import { useDispatch } from 'react-redux';
import axios from 'axios';
import Signup1 from './Signup1';
import { formattedDOB } from './Signup1';
import validateForm from './RegisterValidation'
//import {formattedDOB} from './Signup1/'
//import { useAuth } from './Auth';
//import { useSelector } from 'react-redux';
export default function LoginPage() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [values, setValues] = useState({ 
    //username:'',      
 email: '',        
     password: ''    })    
     const navigate = useNavigate();
     //const [loggedIn, setLoggedIn] = useState(false);
     const [errorsRegisteration, setErrorsRegisteration] = useState({});
  const [errors, setErrors] = useState({})
  const [backendError, setBackendError] = useState([])
  const [showSignUp, setShowSignUp] = useState(false);
const [backendErrorRegister, setBackendErrorRegister] = useState(null);
  //const { updateLoginStatus } = useAuth();

  //Axios.defaults.withCredentials = true;

 //const users=useSelector((state)=>state.nameData.users)
  //const dispatch= useDispatch();
  const handleInput = (event) => {       
     setValues(prev => ({...prev, [event.target.name]: [event.target.value]}))    }    
     const handleSubmit =async(event) => {        
      event.preventDefault();  
      try{     
       const err = Validation(values);  
       setErrors(err);       
        if(err.email === "" && err.password === "") {           
          
          const response = await axios.post(`${apiUrl}/logins`, values);
          console.log(response.data)
          navigate('/dashboard'); 
          localStorage.setItem('loggedIn', 'true');
          window.location.reload();
        //   if (response.data.loggedIn) {
        //     navigate('/dashboard'); // Redirect to dashboard upon successful login
        //   } else {
        //     console.error('Login failed:', response.data);
        //   }
        // 
      }
       }
        catch (error) {
          console.error(error.response.data);
        }
      };
      useEffect(() => {
        console.log('apiUrl:', apiUrl);
        console.log('Signup state:', showSignUp);
      }, [showSignUp]);
      const handleOk = () => {
        // Navigate to the login page
        window.location.href = '/';
      };
      const handleSignUpClick = async() => {
        setShowSignUp(true);
      };
      const handleSignUpClose = () => {
        setShowSignUp(false);
      };
      const handleRegistration=async(userData)=>{
       // e.preventDefault();  
        try {
          //const dob = formattedDOB(userData);
          //console.log("user details",userData)
          //const err = validateForm({ ...userData, dob })
          const err = validateForm(userData)
          console.log("user details1",userData)
          console.log("Validation Errors:", err);
          setErrorsRegisteration(err)
          if(err.firstname === "" && err.surname === "" && err.email === "" && err.phoneNo === "" && err.password === "" && err.dob === "" && err.gender === "" ) 
          {      console.log("true")
            const dob = formattedDOB({userData});
          console.log("User Data with DOB:", { ...userData, dob });

           const response = await axios.post(`${apiUrl}/register2`, {...userData,dob});
          console.log(response.data);
          alert("registered sucessfully. please login ")
          //navigate("/")
          setBackendErrorRegister(null);
          handleOk();
          // Handle successful registration (e.g., display a success message, redirect to login page)
        } }catch (error) {
          // Handle error response from the backend
          if (error.response) {
            // If the error contains a response from the backend
            setBackendErrorRegister(error.response.data.message); // Assuming the backend sends an error message
          } else {
            // If the error does not contain a response from the backend
            setBackendErrorRegister('An error occurred. Please try again.'); // Generic error message
          }
        }
      }
  return (
    <><body> <div className={style.loginmain_content}>
  <div className={style.content_right}>
    
  <img src='/Images/logo-adroitte.png' className={style.login_logo}></img>
  </div>
  {/* <div className={style.content_left}> */}
  <div className={`style.content_left ${showSignUp ? style.dull : ''}`}>
    <div className={style.loginform}>
    <form action='' onSubmit={handleSubmit} className={style.login}>
  <div class="form-group">
  
    <input type="email" class="form-control" value={values.email} name="email" aria-describedby="emailHelp" placeholder="Enter Email" onChange={handleInput}/>
   {errors.email && <span className='text-danger'>{errors.email}</span>}
  </div><br/>
  <div class="form-group">
   
    <input type="password" class="form-control" value={values.password} name="password" placeholder="Password" onChange={handleInput}/>
  
    {errors.password && <span className='text-danger'>{errors.password}</span>}</div>
  <br/>
  <div className={style.btnstyle}>
  <div>
  <button type="submit" className={`btn w-100 ${style.customButton}`}>Login</button>
  {backendError && <span className='text-danger'>{backendError}</span>}</div> 
  <div><br/>
  <button type="button"  onClick={handleSignUpClick} className={`btn w-100 ${style.customButton}`}>Create Account</button></div>
   
    <div><br/>
    <Link to="forgotpassword"className={style.forgetPaslink}>Forgot Password</Link></div>

    </div><br/>
    <div class={style.socialmediaSignup}>
        <p>Or Sign Up Using</p>
        <Link to="facebook"><i class="fa-brands fa-facebook-f" style={{color: "white",backgroundColor:"#2a77f2", borderRadius:"50%", padding:"8px" ,margin:"7px"}} ></i></Link>
        <Link to="twitter"><i class="fa-brands fa-twitter" style={{color: "white" ,backgroundColor:"#2a77f2",borderRadius:"50%", padding:"8px",margin:"7px"}}></i></Link>
        <Link to="google"><i class="fa-brands fa-google" style={{color: "white", backgroundColor:"red",borderRadius:"50%", padding:"8px",margin:"7px"}}></i></Link>
    </div>
    
</form></div>
    </div>
    
    {showSignUp && <Signup1 onClose={handleSignUpClose}  onRegister={handleRegistration} formattedDOB={formattedDOB} backendErrorRegister={backendErrorRegister} errorsRegisteration={errorsRegisteration}/>}

    
    </div> </body> </>
  )
}
