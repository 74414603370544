 export function validateClientId(clientId) {
    if (!clientId) {
      return 'Client ID is required.';
    }
    // You can add more specific validation rules here
    return null;
  }
  export function validateProjectName(projectName) {
    if (!projectName) {
      return 'Project Name is required.';
    }
    // You can add more specific validation rules here
    return null;
  }
  // export function validateGstno(gstno) {
  //   if (!gstno) {
  //     return 'null';
  //   }
  //   // You can add more specific validation rules here
   
  // }
  export function validateProjectDescription(projectDescription) {
    if (!projectDescription) {
      return 'Project Description is required.';
    }
    // You can add more specific validation rules here
    return null;
  }
  
