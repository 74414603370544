export function validatenewParticulrs(newParticulrs) {
    if (!newParticulrs.trim()) {
      return 'Particulars is required.';
    }
    // You can add more specific validation rules here
    return null;
  }
  export function validatenewPrice(newPrice) {
    if (!newPrice.trim()) {
      return 'Cost is required.';
    }
    // You can add more specific validation rules here
    return null;
  }
 