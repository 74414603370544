import React, { useState } from 'react';
import axios from 'axios';

import style from '../CSS/Style.module.css';
export const formattedDOB = ({ userData }) => {
  return `${userData.dob.year}-${userData.dob.month}-${userData.dob.date}`;
};

const Signup=({onClose,onRegister,backendErrorRegister,errorsRegisteration})=> {
  
    const [userData, setUserData] = useState({
        firstname: "",
       surname: "",
       email: "",
       phoneNo:"",
        password: "",
       
        
        dob:{date:"",
        month:"",
        year:""},
        gender:""
      });
// const formattedDOB = `${userData.dob.year}-${userData.dob.month}-${userData.dob.date}`;
//     const [firstname, setFirstname] = useState('');
// const [surname, setSurname] = useState('');
// const [email, setEmail] = useState('');
// const [phoneNo, setPhoneNo] = useState('');
// const [password, setPassword] = useState('');
// const [dob, setDob] = useState({ date: '', month: '', year: '' });
// const [gender, setGender] = useState('');
const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
 

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === 'date' || name === 'month' || name === 'year') {
  //     const dob = { ...userData.dob, [name]: value };
  //     if (dob.date && dob.month && dob.year) {
  //       setUserData({ ...userData, dob });
  //     }
  //   //const [year, month, date] = value.split('-');
  //   //setUserData({ ...userData, dob: { year, month, date } });
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'date' || name === 'month' || name === 'year') {
      setUserData({ 
        ...userData, 
        dob: {
          ...userData.dob,
          [name]: value 
        }
      });
  } else if (name === 'gender') {
    setUserData({ ...userData, [name]: value });
  } else {
    setUserData({ ...userData, [name]: value });
  }
  };

  const handleRegister = async (event) => {
    event.preventDefault();  
 
     
    
      
   onRegister(userData)
    
  };
 

  return (
    
    <div  className={style.modal1}>
    
      <div className={style.modalContent1}>
      <div className={style.modalHeader}>
      <div className={style.modalButtons1}>
      <button className={style.closeButton} onClick={onClose}>
              Close
            </button></div></div>
      <h2><center>SignUp</center></h2><br/>
      <div>
      <form action='' onSubmit={handleRegister}>
      
      <div class="row">
      <div class="col-md-6">
     
  <div className='form-group'>
  <input type="firstname" class="form-control" value={userData.firstname} name="firstname"  placeholder=" firstname"  onChange={handleChange} />
 {errorsRegisteration.firstname && <span className='text-danger'>{errorsRegisteration.firstname}</span>} 
</div></div>

 {/* {errors.username && <span className='text-danger'>{errors.username}</span>} */}

<div class="col-md-6">
<div class="form-group" >
  <input type="surname" class="form-control" value={userData.surname} name="surname"  placeholder=" Surname"  onChange={handleChange} />
 {errorsRegisteration.surname && <span className='text-danger'>{errorsRegisteration.surname}</span>}
</div></div></div><br/>
<div class="form-group">
 
  <input type="email" class="form-control" value={userData.email} name="email" placeholder="Email address" onChange={handleChange} />

  {errorsRegisteration.email && <span className='text-danger'>{errorsRegisteration.email}</span>}
  </div><br/>
  <div class="form-group">
 
  <input type="phoneNo" class="form-control" value={userData.phoneNo} name="phoneNo" placeholder="Phone Number"  onChange={handleChange} />

  {errorsRegisteration.phoneNo && <span className='text-danger'>{errorsRegisteration.phoneNo}</span>}
  </div><br/>
<div class="form-group">
 
  <input type="password" class="form-control" value={userData.password} name="password" placeholder="Password"  onChange={handleChange} />

  {errorsRegisteration.password && <span className='text-danger'>{errorsRegisteration.password}</span>}
  </div>
<br/>
<p>Date of Birth</p>

<div class="row">
      <div class="col-md-4">
      <div class="form-group">
      <select value={userData.dob.date} name="date" onChange={handleChange} >
<option value=''>Selact Date</option>
{Array.from({ length: 31 }, (_, index) => index + 1).map((date) => (
    <option key={date} value={date}>{date}</option>))}
    </select> </div></div>
 {/* {errorsRegisteration.dob && <span className='text-danger'>{errorsRegisteration.dob}</span>} */}


 <div class="col-md-4">
      <div class="form-group">
  
<select value={userData.dob.month} name="month"onChange={handleChange} >
  <option value="">Select Month</option>
  {monthNames.map((month, index) => (
    <option key={index} value={index + 1}>{month}</option>
  ))}
</select>
</div></div>
<div class="col-md-4">
      <div class="form-group"><select value={userData.dob.year} name="year" onChange={handleChange} >
  <option value="">Select Year</option>
  {Array.from({ length: 100 }, (_, index) => new Date().getFullYear() - index).map((year) => (
    <option key={year} value={year}>{year}</option>
  ))}
</select></div></div> 
{errorsRegisteration.dob && <span className='text-danger'>{errorsRegisteration.dob}</span>}
</div><br/> 
<p>Gender</p>
<div className='form-group'>
  <label>
    <input
      type="radio"
      value="male"
      checked={userData.gender === 'male'}
      onChange={handleChange} 
      name="gender" 
    />
    Male
  </label>&nbsp;&nbsp;&nbsp;
  <label>
    <input
      type="radio"
      value="female"
      checked={userData.gender === 'female'}
      onChange={handleChange} 
      name="gender" 
    />
    Female
  </label>&nbsp;&nbsp;&nbsp;
  <label>
    <input
      type="radio"
      value="other"
      checked={userData.gender === 'other'}
      onChange={handleChange} 
      name="gender" 
    />&nbsp;&nbsp;&nbsp;
    Other
  </label>
  {errorsRegisteration.gender && <span className='text-danger'>{errorsRegisteration.dob}</span>}
</div>
<br/>
<div className={style.btnstyle}>
{backendErrorRegister && <div>{backendErrorRegister}</div>}
<button type="submit" className={`btn w-100 ${style.customButton}` } >Register</button>
{/* {backendError && <span className='text-danger'>{backendError}</span>} */}
</div> </form>

    </div></div></div>
  );
}

export default Signup;


