// InvoiceDetailsModal.js
import React from 'react';
import style from '../CSS/Styles.module.css'
import { format } from 'date-fns';
const InvoiceDetailsModal = ({ invoiceData, onClose, onDownload }) => {

    if (!invoiceData) {
        return null;
      }
    
      const {
        invoice,
        client,
        invoiceItems,
      } = invoiceData;
      const issuedate = invoice.issueDate;
      const duedate = invoice.dueDate;
      const formattedIssueDate = format(new Date(issuedate), 'dd-MM-yyyy');
      const formattedDueDate = format(new Date(duedate), 'dd-MM-yyyy');
    
  return (
    <div className={style.modal}>
      <div className={style.modalContent}>
      <div className={style.modalHeader}>
        
          <div className={style.modalButtons}>
            {/* Download button */}
            
            <button className={style.downloadButton} onClick={()=>onDownload(invoice.invoiceId)}>
              Download Invoice
            </button>
            {/* Close button */}
            <button className={style.closeButton} onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      <div class={style.header}>
    <p><b>Adroitte</b></p>
    <p>100 Feet Rd, Srinivagilu,</p>
    <p>Bengaluru, Karnataka 560047</p>
    <p>billing@adroitte.com</p>
    <p><b>GST No:29AASFA5324P2ZP</b></p></div>
        {/* Display invoice details
        <p>Invoice ID: {invoice.invoiceId}</p>
        <p>Client Name: {client.client_name}</p>
        {/* Add more details as needed */}
        {/* Download button */}

        <div class={style.contentSection2}>
  <div class={style.contentSection2Left2}>
  <p><i>Billed To</i>:</p>
    <p><b>{client.client_name}</b></p>
   
    <p>{client.gst_no}</p>
    <p>{client.addressline1}</p>
    <p>{client.addressline2}</p>
    <p>{client.city}
  {client.country}&nbsp;{client.zip}</p>
  </div>
  <div class={style.contentSection2Right2}>
  <p><b>Invoice Number</b></p>
  <p>{invoice.invoiceNumber}</p>
  <p><b>Issue Date :</b> {formattedIssueDate}</p>
  <p><b>Due Date :</b> {formattedDueDate}</p>
  </div>
</div>
<div class={style.contentSection3}>
<table class={style.tableItem}>
<thead>
<tr>
<th class={style.firstCol}>No</th>
<th class={style.secondCol}>Particulars</th>
<th class={style.thirdCol}>Cost</th>
</tr>
</thead>
<tbody>
{
  invoiceItems.map(element => 
    <tr>
    <td class={style.firstCol}>{element.no}</td>
    <td class={style.secondCol}>{element.particulars}</td>
    <td class={style.thirdCol}>{client.currency}{element.cost}</td></tr> )
  }
<tr><td colspan="2"> Sub total :</td>
<td>{client.currency} {invoice.subTotal}</td></tr>
<tr><td colspan="2"> GST :</td>
<td>{client.currency} {invoice.gst}</td></tr>
<tr><td colspan="2"> Grand Total :</td>
<td>{client.currency} {invoice.totalAmount}</td></tr>
 
</tbody>
</table>

</div>


<div class={style.contentSection4}>
<div class={style.section4Left4}>
<p><i><b>Comments</b></i></p>
<p>1.Total payment due in 15 days</p>	
<p>2. Inform once payment made with cheque no or transaction id	</p>
<p>3. Make all cheques payable to 'Adroitte'</p>	
<p>4. Wire Transfer Details	</p>
<p>"Account Holder Name: M/S.ADROITTE</p>
<p>Account Type: Current</p>
<p>Bank Name: ICICI Bank Ltd.</p>
<p>Swift Code: ICICINBBCTS</p>
<p>Account Number :004705009072</p>
<p>IFSC: ICIC0000047</p>
<p>Branch Name: Koramangala Branch, Bangalore, India</p>
<p>Bank Name: J P MORGAN CHASE BANK NEW YORK. (Intermediary Bank)</p>
<p>Field 56: Swift Code: CHASUS33XXX</p>
<p>Field 57: Beneficiary Bank: ICICI Bank Ltd, Mumbai (India)</p>
<p>ABA FED No : 21000021"	</p>
<p>"Account Type: Current</p>
<p>Bank Name: ICICI Bank Ltd.</p>
<p>Swift Code: ICICINBBCTS</p>
<p>Account Number :004705009072</p>
<p>IFSC: ICIC0000047</p>
<p>Branch Name: Koramangala Branch, Bangalore, India</p>
<p>Bank Name: J P MORGAN CHASE BANK NEW YORK. (Intermediary Bank)</p>
<p>Field 56: Swift Code: CHASUS33XXX</p>
<p>Field 57: Beneficiary Bank: ICICI Bank Ltd, Mumbai (India)</p>
<p>ABA FED No : 21000021</p>
</div>
<div class={style.section4Middle} >
<div class={style.payImg}>
<div class={style.section4MiddleImg1}>
<img src='../Images/payt.png' alt="paytm"></img></div>
<div class={style.section4MiddleImg2}>
<img src='../Images/gpay.png' alt="Sample Image"></img></div>

</div>

</div>
<div className={style.section4Right4}>
<div class={style.section4Right4SubItem1}><p>For Adroitte</p>
<p class={style.sign}>Danny G Sam (signed)</p>
<p>Danny G Sam</p></div>
<div class={style.section4Right4SubItem2}>
<img src='../Images/QRcode.png'></img>
</div>
</div></div>
<center><i>Thank you for your Bussiness</i></center>
       
</div>

      </div>
    
  );
};

export default InvoiceDetailsModal;
