import React,{useState} from 'react'
import style from '../CSS/Style.module.css'
import axios from 'axios';
import { Link,useNavigate } from 'react-router-dom'
export default function Header() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const response = await axios.post(`${apiUrl}/logout`);
      console.log(response.data.message);
      navigate('/'); 
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  return (
 <>
<body><div className={style.main_container}>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <Link to='/dashboard' class="navbar-brand"><img src='/Images/logo-adroitte.png' className={style.logo}></img></Link>
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class={`collapse navbar-collapse  ${style.navList}`}id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <Link to='/dashboard' class="nav-link">Dashboard <span class="sr-only">(current)</span></Link>
      </li>
      <li class="nav-item">
        <Link to ='/invoicePage' class="nav-link" href="#">Invoices<span class="border-bottom-2"></span></Link>
      </li>
      <li class="nav-item dropdown">
        <Link to ='/addclient' class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         Clients
        </Link>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <Link to ='/addclient'  class="dropdown-item" href="#">Add Clients</Link>
          <Link to ='/addproject'  class="dropdown-item" href="#">Add Projects</Link>
          <Link to ='/manageClients' class="dropdown-item" href="#">Manage Clients</Link>
          </div>
      </li>
      <li class="nav-item">
        <Link to='/report' class="nav-link" >Reports</Link>
      </li>
      <li class="nav-item">
        <Link to='/help' class="nav-link" >Help</Link>
      </li>
    </ul>
    
  </div>
  <div class={`collapse navbar-collapse ${style.profileSection}`} id="navbarSupportedContent">
    <ul class={`navbar-nav mr-auto ${style.profileSectionItems}`}>
      <li class="nav-item active">
      <i className="fas fa-1x fa-solid fa-bell"></i> <span class="sr-only">(current)</span>
      </li>
      <li class="nav-item">
      <i className="fa-sharp fa-solid fa-gear"></i>
      </li>
      {/* <li class="nav-item"> */}
      <li class="nav-item dropdown">
 
      <Link to ='/login' class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa-solid fa-user"></i>
        </Link> 
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <button onClick={handleLogout}>Logout</button>
          {/* <Link to ='/addproject'  class="dropdown-item" href="#">Add Projects</Link>
          <Link to ='/manageClients' class="dropdown-item" href="#">Manage Clients</Link> */}
          </div>
      </li>
   
      {/* </li> */}
    
      
    </ul>
    
  </div>
</nav>
 </div>
 </body>
 </>
  )
}
