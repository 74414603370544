
import axios from "axios";
import{ validateClientId,validateProjectName,validateProjectDescription, validateGstno } from "./AddProjectValidation";
//import validateProjectName from "./AddProjectValidation";
//import validateProjectDescription from "./AddProjectValidation";
import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
//import { useParams } from 'react-router-dom'
import style from "../CSS/Style.module.css";
import Footer from "./Footer";
import Header from "./Header";
export default function AddBussiness() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
   // const [errors, setErrors] = useState({});
    const [clientnames,setClientNames]=useState([])
    const [selectedClientName,setSelectedClientName]=useState('')
    //const [clientDetails,setclientDetails]=useState(null)
    const [error,setError]=useState('')
    const [clientId, setClientId] = useState('');
    const [projectName, setProjectName] = useState('');
    //const [gstno,setGstno]=useState('')
    const [projectDescription, setProjectDescription] = useState('');
    const [projecterror,setProjectError]=useState('')
    const [validationErrors, setValidationErrors] = useState({
        clientId: null,
        projectName: null,
        //gstno:null,
        projectDescription: null,
      });
    // const handleInput = (event) => {
    //     setValues((prev) => ({
    //       ...prev,
    //       [event.target.name]: [event.target.value],
    //     }));
    //   };
   
    useEffect(()=>{
        fetchclientNames();
      },[])
     
      const fetchclientNames = async () => {
        try {
          const response = await axios.get(`${apiUrl}/clientnames`);
          setClientNames(response.data);
        console.log(clientnames)
        setError('')
        }catch(err){
          setError('An error occurred');
      }
         
      }
    
      const handleProjestNameChange =async  (e) => {
        const cname=e.target.value
        setSelectedClientName(cname);
    
    
      if(cname){
        try{
          const response= await axios.get(`${apiUrl}/getClientID/${cname}`)
          setClientId(response.data.clientId);
          setError('')
          console.log(error)
        }catch(err) {
          if (err.response && err.response.status === 404) {
            setError('User not found');
          } else {
            setError('An error occurred');
          }
          setClientId(null);
          
      }
    }
    
    };
//     const handleSubmit = (event) => {
//     event.preventDefault();
//     const err = Validation(values);
//     setErrors(err);
//     console.log(values);
//     console.log(err);

//     if (
      
//         err.clientid === "" &&
//       err.projectname === "" &&
//       err.description === ""
      
//     ) {
//       axios
//         .post('http://localhost:8500/addproject', values)
//         .then((res) => {
//           navigate('/ProjectSucessPage');
//         })
//         .catch((err) => {
//         if (err.response && err.response.status === 400) {
//           setError(err.response.data.error);
//         } else {
//             setError('project name exist');
//         }});
//     }
//   };
    

const handleCreateProject = async () => {

    const clientIdError = validateClientId(clientId);
    const projectNameError = validateProjectName(projectName);
    //const gstnoError =validateGstno(gstno);
    const projectDescriptionError = validateProjectDescription(projectDescription);

    // Set the validation errors in state
    setValidationErrors({
      clientId: clientIdError,
      projectName: projectNameError,
      //gstno:gstnoError,
      projectDescription: projectDescriptionError,
    });

    // Check if there are any validation errors
    if (clientIdError || projectNameError || projectDescriptionError) {
        return;
    }
 
    try {
      const response = await fetch(`${apiUrl}/addproject`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientId,
          projectName,
          //gstno,
          projectDescription,
        }),
      });
        if( response.status===200){
            console.log('Project created successfully');
            navigate('/ProjectSucessPage');
        }
        else {
           setProjectError('Project creation failed');
    }
}
catch(err) {
          if (err.response && err.response.status === 400) {
            setProjectError(err.response.data.error);
            } else {
                setProjectError('project name exist');
            }}}
  return (
    <>
    <body>
    <p>HI</p>
      <Header />
   
      <div className={style.main_content}>
      <div className={style.breadcrumps}>
         <ul className={style.breadcrumpsItems} >
          <li>
          <Link to='/dashboard'>Home </Link></li>
          
         <li> <Link to='/invoice'>Invoice</Link></li></ul>
        </div>
        <div className={style.contentwrpper}>
            <div className={style.contentSection1}>
              <h1>  Please Add Projects to the Clients</h1>
            </div>
            <div>
              <div className={style.contentwrpperForm}>
               
                  <div className={style.form}>
                 
                    <div>
                    <label>Select a Client Name:</label>
                <select value={selectedClientName} onChange={handleProjestNameChange}>
                  <option value="">select a client name</option>
                  {clientnames.map((cliname)=>(
                    <option key ={cliname} name="clientanme"  value={cliname}>
                    
                      {cliname}
                    </option>
                  ))}
                </select>
               
                {error && <p style={{color:'red'}}>{error}</p>}
                { clientId && (
                   <div >
                   <label for="clientDetails.id">Client ID</label>
                   <input
                     type="text"
                     class="form-control"
                     value={clientId}
                     name="clientDetails.id"
                     placeholder="Client ID"
                     onChange={(e)=>setClientId(e.target.value)}
                   />{validationErrors.clientId && <span className='text-danger'>{validationErrors.clientId}</span>}
                 </div>)}

                    <div >
                      <label for="projectName">Project Name</label>
                      <input
                        type="text"
                        class="form-control"
                        value={projectName.replace(/^\s+/g, '')}
                        name="projectName"
                        placeholder="Project Name"
                        onChange={(e)=>setProjectName(e.target.value)}
                      />  {validationErrors.projectName && <span className='text-danger'>{validationErrors.projectName}</span>}
                    </div>
                   {/* <div class="col-md-12">
                      <label for="gstno">GST NO</label>
                      <input
                        type="text"s
                        class="form-control"
                        value={gstno}
                        name="gstno"
                        placeholder="
                        gstno"
                        onChange={(e)=>setGstno(e.target.value)}
                      /> {validationErrors.gstno && <span className='text-danger'>{validationErrors.gstno}</span>}
                </div>*/}
                    <div class="col-md-12">
                      <label for="projectDescription">Description</label>
                      <input
                        type="text"
                        class="form-control"
                        value={projectDescription.replace(/^\s+/g, '')}
                        name="projectDescription"
                        placeholder="
                        Description"
                        onChange={(e)=>setProjectDescription(e.target.value)}
                      /> {validationErrors.projectDescription && <span className='text-danger'>{validationErrors.projectDescription}</span>}
                    </div><br/>
                    <div class="text-center">
                  <button  onClick={handleCreateProject} class="btn btn-secondary">
                  create Project
                  </button> {projecterror && <span className='text-danger'>{projecterror}</span>}</div>   
                        </div></div></div></div>
        </div>
        </div>
       
        </body>
        </>
  )
}
