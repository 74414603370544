import React from 'react'
import Header from "./Header";
import style from '../CSS/Style.module.css'
import Footer from "./Footer";
import { Link,useNavigate } from "react-router-dom";
export default function Help() {
  return (
    <><Header/><body>
    <div className={style.main_content}>
        <div className={style.breadcrumps}>
         <ul className={style.breadcrumpsItems} >
          <li>
          <Link to='/dashboard'>Home </Link></li>
          
         <li> <Link to='/help'>Help</Link></li></ul>
        </div>
        <div className={style.contentwrpper}>
   <h2><center>Help Page</center></h2></div></div></body><Footer/></>
  )
}
