function Validation(values)
{
    
    let error = {}
   
    // if(values.bussinessname === "")
    // {
    //     error.bussinessname = "Bussiness Name should not be empty"
    // }
    
    // else{
    //     error.bussinessname=""
    // }
    if(values.clientname ==="")
    {
    error.clientname = "Client Name should not be empty"
    }
  
    else{
        error.clientname=""
    }

    if(values.gstno === "")
    {
        error.gstno = ""
    }
    
    
    if(values.currency === "")
    {
        error.currency = "Bussiness Name should not be empty"
    }
    
    else{
        error.currency=""
    }

    if(values.email === "")
    {
        error.email = "Email should not be empty"
    }
   
    else{
        error.email=""
    }

    if(values.phoneno === "")
    {
        error.phoneno = "Bussiness Name should not be empty"
    }
    
    else{
        error.phoneno=""
    }
    if(values.addressline1 ==="")
    {
       error.addressline1= "Address Should not be empty"
    }
    else{
        error.addressline1=""
    }
    if(values.addressline2 ==="")
    {
       error.addressline2= "Address Should not be empty"
    }
    else{
        error.addressline2=""
    }
    if(values.city ==="")
    {
       error.city= "Address Should not be empty"
    }
    else{
        error.city=""
    }
    if(values.zip ==="")
    {
       error.zip= "Zip code Should not be empty"
    }
    else{
        error.zip=""
    }
    if(values.country ==="")
    {
       error.country= "Country Should not be empty"
    }
    else{
        error.country=""
    }
    
    return error;
    
}
export default Validation;