import React from 'react'
import { Link } from 'react-router-dom'
import style from '../CSS/Style.module.css'
import Header from './Header'
import Footer from "./Footer";
export default function Report() {
  return (
    <><Header/><body>
      <div className={style.main_content}>
          <div className={style.breadcrumps}>
           <ul className={style.breadcrumpsItems} >
            <li>
            <Link to='/dashboard'>Home </Link></li>
            
           <li> <Link to='/report'>Reports</Link></li></ul>
          </div>
          <div className={style.contentwrpper}>
     <h2><center>Report Page</center></h2></div></div></body><Footer/></>
  )
}
