
import React, { useState } from 'react';
import axios from 'axios';
import style from "../CSS/Style.module.css";
import Footer from "./Footer";
import Header from "./Header";
import { Link,useNavigate } from "react-router-dom";
function InvoiceSucessPage() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [downloaded, setDownloaded] = useState(false);
  const editInvoice =() =>{}

  const downloadLatestInvoice = () => {
    axios
      .get(`${apiUrl}/api/invoices/latest`, { responseType: 'blob' })
      .then((response) => {
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/pdf' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link and trigger a click event to download the file
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'latest_invoice.pdf');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        // Clean up resources
        window.URL.revokeObjectURL(url);
        setDownloaded(true);
      })
      .catch((error) => {
        console.error('Error downloading latest invoice:', error);
      });
  };

  return (
    <>
    <body>
        <Header />
        <div className={style.main_content}>
        <div className={style.breadcrumps}>
           <ul className={style.breadcrumpsItems} >
            <li>
            <Link to='/dashboard'>Home </Link></li>
            
           <li> <Link to='/invoice'>Invoice</Link></li>
            
           <li> <Link to='/invoiceSucessPage'>Download</Link></li></ul>
          </div>
          <div className={style.contentwrpper}>
    <div className={style.buttons}>
 
      <button onClick={downloadLatestInvoice} class="btn btn-primary">Download Saved Invoice</button>
      <br/>
      {downloaded && <p>Invoice downloaded successfully!</p>}
      <button onClick={editInvoice}>Edit Last Invoice Saved</button>
    </div></div></div></body></>
  );
}

export default InvoiceSucessPage;
