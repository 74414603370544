//import axios from 'axios';
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import {validatenewParticulrs,validatenewPrice} from "./InvoiceTableValidation";
import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
//import { useParams } from 'react-router-dom'
import style from "../CSS/Style.module.css";
import Footer from "./Footer";
import Header from "./Header";
import { validatenewParticulrs, validatenewPrice } from "./InvoiceTableValidation";
export default function Invoice() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  // Mapping of currency codes to symbols
  const navigate = useNavigate();
  // const [tablevalidationErrors, setTableValidationErrors] = useState({
  //   newParticulrs: null,
  //   newPrice: null,
    
  // });
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [issueddate, setIssueddate] = useState(new Date());
  const [duedate,setDuedate]=useState(new Date());
  const initialTableData = [];
  const [tableData, setTableData] = useState(initialTableData);
  const [editMode,setEditMode]=useState(null);
  const [newParticulrs, setNewParticulrs] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [clientnames,setClientnames]=useState([])
const [selectedclientname,setSelectedClientname]=useState('');
  const [clientDetails,setClientDetails]=useState([]);
  const[projects,setProjects]=useState([])
  const [error,setError]=useState({});
  const[saveInvoiceerror,setSaveInvoiceerror]=useState('')
  const[selectedprojectname,setSelectedprojectname]=useState('')
  //const[subtotal,setSubtotal]=useState('')
  //const[gst,setGst]=useState('')
  //const[grandtotal,setGrandtotal]=('')

  const handleDueDateChange = (event) => {
    setDuedate(event);
    // const dateObject = new Date(duedate);
    // const formattedDate = dateObject.toLocaleDateString('en-GB', {
    //   day: '2-digit',
    //   month: '2-digit',
    //   year: 'numeric',
    // });
  };

  const handleIssuedDateChange  = (event) => {
    setIssueddate(event);
  };
  useEffect(() => {
    // Calculate due date after 15 days from the issue date
    const newDueDate = new Date(issueddate);
    newDueDate.setDate(issueddate.getDate() + 15);
    setDuedate(newDueDate);
  }, [issueddate]);
  
//  const handleIssuedDateChange=(event)=>{
//   const issuedate= new Date(event.target.value);
//   const formattedDate = formatDateString(issuedate);
//   setIssueddate(formattedDate);
//  }

//  const handleDueDateChange=(event)=>{
//   const dueDate=new Date(event.target.value);
//   const formattedDate = formatDateString(dueDate);
//   setDuedate(formattedDate);
//  }
  
//  const formatDateString = (dateString) => {
//   // Convert the date string to a Date object
//   //const dateObject = new Date(dateString);

//   // Format the date as 'DD/MM/YYYY'
//   const formattedDate = new Intl.DateTimeFormat('en-GB', {
//     day: '2-digit',
//     month: '2-digit',
//     year: 'numeric',
//   }).format(dateString);

//   return formattedDate;}

  useEffect(() => {
    fetch(`${apiUrl}/api/fulldetailsofclient`)
      .then((response) => response.json())
      .then((data) =>setClientnames(data)
        
      )
      .catch((error) => console.error('Error:', error));
  }, []);

 

 


 useEffect(() => {
  if (selectedclientname) {
    fetch(`${apiUrl}/api/clientdetails/${selectedclientname}`)
      .then((response) => response.json())
       .then((data) => setClientDetails(data)
      )
     
     
     .catch((error) => console.error('Error:', error));
      // console.log(projects)
   } else {
    setClientDetails([]);
  }
 }, [selectedclientname]);


useEffect(() => {
  if (selectedclientname) {
    fetch(`${apiUrl}/api/bussiness/${selectedclientname}`)
      .then((response) => response.json())
      .then((data) => setProjects(data))
     
      .catch((error) => console.error('Error:', error));
      console.log(projects)
  } else {
    setProjects([]);
  }
}, [selectedclientname]);

const validateForm =()=>{
  let isValid = true;
  const newErrors={}
  const particulrsError=validatenewParticulrs(newParticulrs)
  if(particulrsError){
    newErrors.particulrs=particulrsError;
    isValid=false;
  }
  const priceError=validatenewPrice(newPrice)
  if(priceError){
    newErrors.price = priceError;
    isValid=false;
  }
  setError(newErrors);
  return isValid;
}

  const addRow = (e) => {
    
    if(validateForm()){
   
      
      
      const newRow = {
        no: tableData.length + 1,
        particulrs: newParticulrs,
      price: parseFloat(newPrice), 
      }
      
      setTableData([...tableData, newRow]);


      setNewParticulrs('');
      setNewPrice('');
      setError({});
      }
    }
    
  
  //   const newRow = {
  //     no: tableData.length + 1,
  //     // particulrs: newParticulrs,
  //     // price: newPrice,
  //   };
  //   setTableData([...tableData, newRow]);
  //   setNewParticulrs("");
  //   setNewPrice("");
  // };
//}
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setTableData({ ...tableData, [name]: value } );
  // };
  const handleDelete = (no) => {
    // Filter the data to remove the row with the specified id
    const updatedData = tableData.filter((row) => row.no !== no);
    setTableData(updatedData);
  };

  const handleEditClick = (no) => {
    setEditMode(no);
  };

  const handleSaveClick = (no, newItem, newAmount) => {
    // Find the index of the item being edited
    const index = tableData.findIndex((item) => item.no === no);

    if (index !== -1) {
      // Create a copy of the data array and update the edited item
      const updatedData = [...tableData];
      updatedData[index] = { ...updatedData[index], particulrs: newItem, price: newAmount };

      // Update the state with the new data and exit edit mode
      setTableData(updatedData);
      setEditMode(null);
    }
  };

  const handleCancelClick = () => {
    setEditMode(null); // Cancel edit mode
  };


  // Define a function to calculate the sum of values in the "cost" column
 // const totalCost = tableData.reduce((accumulator, current) => accumulator + parseFloat(current.price), 0);
  //const tax = (totalCost*18)/100;
  //
  const calculateTotal = () => {
    const total =tableData.reduce((accumulator, current) => accumulator + parseFloat(current.price), 0);
  return total;
  };
  
 const findCurrency=()=> {
  const currency1= clientDetails.map((details) => details.currency)
  return currency1;
 }

  const calculateGST = (total, currency1) => {
    console.log(total,currency1)
    // Check if the currency is INR
    if (currency1 == 'INR') {
      const gstRate = 0.18; // 18% GST
      const gstAmount = total * gstRate;
      console.log('gst amount',gstAmount)
      //const totalWithGST = totalCost + gstAmount;
     // return totalWithGST;
     return gstAmount;
   
    }
    else{
    return 0
    }
    // No tax for other currencies
  };
  
  const total = calculateTotal(tableData);
  const currency1=findCurrency()
  const gstAmount = calculateGST(total, currency1);
  console.log(gstAmount)
  const grandTotal=total+gstAmount;



 


  // // Function to generate a unique invoice number
  // const generateInvoiceNumber = async () => {
  //   const prefix = 'ADR'; // Invoice prefix
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = today.getMonth() + 1; // Month is zero-based, so we add 1
  //   const day = today.getDate();
  
  //   // Fetch the last invoice number from your backend
  //   try {
  //     const response = await fetch('http://localhost:8500/get-last-invoice-number');
  //     const data = await response.json();
  //     const lastInvoiceNumber = data.lastInvoiceNumber;
  
  //     // Parse the last invoice number to extract the counter part
  //     const lastCounter = parseInt(lastInvoiceNumber.split('-').pop());
  //     console.log("lastNO:",lastCounter)
  
  //     // Increment the counter for the next invoice
  //     const nextCounter = lastCounter + 1;
  
  //     // Generate the next invoice number
  //     const nextInvoiceNumber = `${prefix}-${day}${month}${year}-${nextCounter.toString().padStart(3, '0')}`;
  
  //     // Return the generated invoice number
  //     setInvoiceNumber(nextInvoiceNumber);
  //     setCounter(nextCounter); 
  //   } catch (error) {
  //     console.error('Error fetching last invoice number: ' + error);
  //     // Handle the error or provide a default invoice number
  //     setInvoiceNumber('ADR-Default-001');
  //   }
  // };
  
  // useEffect(() => {
  //   generateInvoiceNumber(); // Call the function when the component mounts
  // }, []);

  
  useEffect(() => {
    fetch(`${apiUrl}/get-last-invoice-number`)
      .then((response) => response.json())
      .then((data) =>{setInvoiceNumber(data.invoiceNumber)}
        
      )
      .catch((error) => console.error('Error:', error));
  }, []);

  const handleSaveInvoice = () => {
    const invoiceData = {
      invoiceNumber: invoiceNumber,
      clientId: selectedclientname, // Replace with the actual client ID
      ProjectId: selectedprojectname, // Replace with the actual project ID
      issueDate: issueddate,
      dueDate: duedate,
      tableData: tableData,
      subTotal:total,
      gst:gstAmount,
      totalAmount:grandTotal

    };
  console.log(invoiceNumber,selectedclientname,selectedprojectname,issueddate,duedate,tableData)
    axios.post(`${apiUrl}/api/save-data`,invoiceData)
      .then(response => {
        console.log('Invoice saved successfully');
            navigate('/invoiceSucessPage');
      })
      .catch(error => {
        setSaveInvoiceerror('Invoice not saved');
        console.error(error);
      });
  };
  


  return (
    <>
      <body>
        <Header />
        <div className={style.main_content}>
        <div className={style.breadcrumps}>
           <ul className={style.breadcrumpsItems} >
            <li>
            <Link to='/dashboard'>Home </Link></li>
            
           <li> <Link to='/invoice'>Invoice</Link></li></ul>
          </div>

          <div className={style.contentwrpper}>
            <div className={style.contentSection1}>
              <div className={style.sectionLeft}>
                <p>
                  <b>Adroitte</b>
                </p>
                <p>
                  <b>GST No:29AASFA5324P2ZP</b>
                </p>
                <p className={style.mail}>billing@adroitte.com</p>
              </div>
              <div className={style.sectionRight}>
                <p>4/1 1st Cross, </p>
                <p>100 Feet Rd, Srinivagilu,</p>
                <p>Bengaluru, Karnataka 560047</p>
              </div>
            </div>
            <div className={style.contentSection2}>
             
              <div className={style.sectionRight}>
                <p><b>Billed to</b></p>
                <label>Select a Clientname:</label>
                <select value={selectedclientname} onChange={(e) => setSelectedClientname(e.target.value)}>
                  <option value="">select a Client Name</option>
                  {clientnames.map((cliname)=>(
                    <option key ={cliname.id} value={cliname.id}>
                      {cliname.client_name}
                     
                    </option>
                  ))}
                </select>
                <label>Select a Project Name:</label>
                <select value={selectedprojectname} onChange={(e)=>setSelectedprojectname(e.target.value)}>
                <option value="">select a Project Name</option>
                  {
                    projects.map((project)=>(
                      <option key={project.projectID} value={project.projectID}>

                        {project.project_name}
                  

                      </option>
                      
                    ))
                  }
                  
                 </select>
                { clientDetails.map((details)=> (
                  <div>
            {/* <input
              type="text"
              name="newInvoiceNumber"
             
              value={details.id}
                ></input>  */}      
         <p>{details.client_name}</p>
                <p>{details.addressline1}</p>
                <p>{details.addressline2}</p>
                <p>{details.city}</p>
                <p>{details.zip}</p>
                <p>{details.country}</p>
              
              </div>))}</div>
            <div className={style.sectionLeft}>
            <label>Invoice Number</label><br/>
            <input
              type="text"
              name="newInvoiceNumber"
             
              value={invoiceNumber}
            ></input>
            <div>
              <br/>
              <label>Issued Date</label>
              <DatePicker
                name="issueddate"
                selected={issueddate}
                onChange={handleIssuedDateChange}
                dateFormat="dd/MM/yyyy"
              />
            </div><br/>
            <div>
              <label>Due Date</label>
               <DatePicker name="dueDate" selected={duedate}  onChange={handleDueDateChange} dateFormat="dd/MM/yyyy"/>
            </div><br/>
            <div>
            <label>GST No:</label> <br/><br/>
            {clientDetails.map((clientdetails)=> (
            <div>
            <p><b>{clientdetails.gst_no}</b></p>
         
              <p>{clientdetails.email}</p>
              <p>{clientdetails.phoneno}</p></div>))}
            </div>
          </div> </div>  
            <div className={style.Section3invoiceFields}>
              {" "}
              <div className={style.AddItem}>
                <label> Particulars</label>&nbsp;&nbsp;&nbsp;
                <input
                  type="text"
                  name="Particulars"
                  value={newParticulrs}
                  onChange={(e) => setNewParticulrs(e.target.value)}
                ></input>
                {error.particulrs && <span className="error">{error.particulrs}</span>}
              </div>
              <div className={style.addItem}>
                {" "}
                <label>Price</label>&nbsp;&nbsp;&nbsp;
                <input
                  type="number"
                  name="price"
                  value={newPrice}
                  onChange={(e) => setNewPrice(e.target.value)}
                  
                />
               {error.price && <span className="error">{error.price}</span>}
              </div>
              <div className={style.addItem}>
                {" "}
                <button type="new-row" className={style.btnstyle1} onClick={addRow}>
                  +
                </button>
                
              </div>
              
            </div>
         
          <table class="table table-bordered p-10">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Particulars</th>
                {clientDetails.map((clientdetails)=> (
                <th scope="col">Cost in {clientdetails.currency}</th>))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row) => (
                <tr key={row.no} >
                  <td>{row.no}</td>
                  <td>
                {editMode === row.no ? (
                  <input type="text" name="particulars" defaultValue={row.particulrs} onChange={(e) => row.particulrs = e.target.value}  />
                ) : (
                  row.particulrs
                )}
              </td>
              <td>   
              {clientDetails.map((clientdetails)=>(clientdetails.currency))} &nbsp;
                {editMode === row.no ? (
                  <input type="text" name="price" defaultValue={row.price} onChange={(e) => row.price = e.target.value}  />
                ) : (
                  row.price
                )}
              </td>
              <td>
                {editMode === row.no ? (
                  <>
                    <button onClick={() => handleSaveClick(row.no,row.particulrs,row.price)}>Save</button>
                    <button onClick={handleCancelClick}>Cancel</button>
                  </>
                ) : (
                  <button onClick={() => handleEditClick(row.no)}>Edit</button>
                )}
              </td>
                  <td><button type="delete-row" className={style.btnstyle1} onClick={()=>handleDelete(row.no)}> x </button></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div class="text-right mt-4 p-5">
      <h5>Invoice Summary</h5>
      <p>Subtotal: {clientDetails.map((clientdetails)=>(clientdetails.currency))} &nbsp; {total}</p>
      <p>Tax (18%):  {clientDetails.map((clientdetails)=>(clientdetails.currency))} &nbsp;{gstAmount}</p>
      <h4>Total:  {clientDetails.map((clientdetails)=>(clientdetails.currency))} &nbsp;{grandTotal}</h4>
    </div>
    <div class="text-center">
                  <button  onClick={handleSaveInvoice} class="btn btn-secondary">
                  Save Invoice
                  </button> {saveInvoiceerror && <span className='text-danger'>{saveInvoiceerror}</span>}</div> 
          </div>

         
         
         
         
         
         
         
         
         
       
            
           
        </div>
        <Footer />
      </body>
    </>
  );
}
