import React, { useEffect, useState } from 'react'
import { Link, useNavigate,useParams } from 'react-router-dom';
import Footer from "./Footer";
import Header from "./Header";
import style from "../CSS/Style.module.css";
const EditClientDetails=({match}) =>{
  const apiUrl = process.env.REACT_APP_API_BASE_URL;  
const [clientData,setClientData]=useState({
    id:null,
    client_name:'',
    gst_no:'',
    currency:'',
    email:'',
    phoneno:'',
    addressline1:'',
    addressline2:'',
    city:'',
    zip:'',
    country:''

})

const navigate=useNavigate();
//const { params } = match;
const {id}=useParams();
//const clientId = params ? params.id : null;
//console.log(match); // Log the match object to the console


useEffect(()=>{
    fetch(`${apiUrl}/clientsdetails/${id}`)
    .then((response)=>response.json())
    .then((data)=>{
        setClientData(data.clientData)
        
    })
    .catch((error)=>{
        console.error('Error fetching client details:', error);
    })
},[id])
console.log('Client Data:', clientData);

const handleUpdate = () => {
    // Send a PUT request to update the client data
    fetch(`${apiUrl}/api/clients/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(clientData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Client updated successfully:', data);
        // Redirect back to the client details table after updating
        navigate('/manageclients');
      })
      .catch((error) => {
        console.error('Error updating client:', error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  };

  return (
    <>
    <body>
        <Header />
        <div className={style.main_content}>
        <div className={style.breadcrumps}>
           <ul className={style.breadcrumpsItems} >
            <li>
            <Link to='/dashboard'>Home </Link></li>
            
           <li> <Link to='/invoice'>Invoice</Link></li></ul>
          </div>
          <div className={style.contentwrpper}>
          <h2><center>Edit Client Details</center></h2> 
         <div className={style.contentwrpperForm} >
    <div className={style.form}>
         
       
            <div className={style.section1InputField}>
              <div className={style.inputField}>
      <label>Name:</label>
      <input
        type="text"
        name="client_name"
        value={clientData.client_name}
        onChange={handleInputChange}
      />
</div>

<div className={style.inputField}>
      <label>Gst No:</label>
      <input
        type="text"
        name="gst_no"
        value={clientData.gst_no}
        onChange={handleInputChange}
      />
</div>
<div className={style.inputField}>
 <label>Currency</label>
      <input
        type="text"
        name="currency"
        value={clientData.currency}
        onChange={handleInputChange}
      /></div>

<div className={style.inputField}>
       <label>Email</label>
      <input
        type="text"
        name="email"
        value={clientData.email}
        onChange={handleInputChange}
      /></div>

<div className={style.inputField}>
       <label>Phone No</label>
      <input
        type="phoneno"
        name="phoneno"
        value={clientData.phoneno}
        onChange={handleInputChange}
      /></div>

<div className={style.inputField}>
       <label>AddressLine1</label>
      <input
        type="text"
        name="addressline1"
        value={clientData.addressline1}
        onChange={handleInputChange}
      /></div>

<div className={style.inputField}>
       <label>AddressLine2</label>
      <input
        type="text"
        name="addressline2"
        value={clientData.addressline2}
        onChange={handleInputChange}
      /></div>

<div className={style.inputField}>
       <label>City</label>
      <input
        type="text"
        name="city"
        value={clientData.city}
        onChange={handleInputChange}
      /></div>

<div className='{style.inputField}'>
       <label>Zip</label>
      <input
        type="text"
        name="zip"
        value={clientData.zip}
        onChange={handleInputChange}
      /></div>

<div className={style.inputField}>
       <label>Country:</label>
      <input
        type="text"
        name="country"
        value={clientData.country}
        onChange={handleInputChange}
      />
</div>
<div className={style.inputField}>
      <button onClick={handleUpdate}>Update</button>
      </div>
      </div>
         </div>
    </div>
    </div>
    </div>
    </body>
    <Footer/>
     </>
  )
}
export default (EditClientDetails)