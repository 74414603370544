import './App.css';
import LoginPage from './components/HTML/LoginPage';
//import InvoicePage from './components/HTML/InvoicePage';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.js';
//import {  Routes} from 'react-router-dom'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
//import {BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
//import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Client1 from './components/HTML/Client1';
import Signup1 from './components/HTML/Signup1';
import HomePage from './components/HTML/Invoice';
import LoginPage1 from './components/HTML/LoginPage1';
import AddClient from './components/HTML/Clients';
import ClientSucessPage from './components/HTML/ClientSucessPage';
import Header from './components/HTML/Header';
import ProtectedRoute from './components/HTML/ProtectedRoute';
//import DynamicTable  from './components/HTML/DynamicTable';
import Dashboard from './components/HTML/Dashboard';
import Clients from './components/HTML/Clients';
import Invoice from './components/HTML/Invoice';
import Report from './components/HTML/Report';
import Help from './components/HTML/Help';
import Header1 from './components/HTML/Header1';
import ManageClients from './components/HTML/ManageClients';
import AddBussiness from './components/HTML/AddBussiness';
import InvoiceSucessPage from './components/HTML/InvoiceSucessPage';
import ProjectSucessPage from './components/HTML/ProjectSucessPage';
import EditClientDetails from './components/HTML/EditClientDetails';
import {useAuth} from './components/HTML/Auth'
import ForgotPassword from './components/HTML/ForgotPassword';
import ResetPAsswordForm from './components/HTML/ResetPAsswordForm';

//import PrivateRoute from './components/HTML/PrivateRoute';
// function PrivateRoute({ path, element }) {


//   return (
//     <Route
//       path={path}
//       element={isLoggedIn ? element : <Navigate to="/" replace />}
//     />
//   );
// }

// const PrivateRoute = ({ component: Component, ...rest }) => {
//  const { isLoggedIn } = useAuth();
  
//   return (
//     <Route
//       {...rest}
//       render={props =>
//         isLoggedIn ? (
//           <Component {...props} />
//         ) : (
//           <Navigate to="/" />
//         )
//       }
//     />
//   );
// };
function App() {
  const isLoggedIn = localStorage.getItem('loggedIn') === 'true';
//   const { isLoggedIn } = useAuth();
//  console.log(isLoggedIn)
  // const isAuthenticated = (req,res)=>{
  //   if(req.session && req.session.user){
    
  //   }else{
  //     res.redirect('/login')
  //   }
  // }
  return (
<Router> 

{isLoggedIn ? (
    <Routes>
  <Route path="/dashboard" element={<Dashboard/> }/>
<Route path='/addclient' element={<Clients/>} />
<Route path='/addproject' element={<AddBussiness/>} ></Route>
        
        <Route path='/invoicePage' element={<Invoice/>}></Route>
        <Route path='/report' element={<Report/>}></Route>
        <Route path='help' element={<Help/>}></Route>
        <Route path='/manageclients' element={<ManageClients/>}></Route>
        <Route path='/clientSucessPage' element={<ClientSucessPage/>}></Route>
        <Route path='/invoiceSucessPage' element={<InvoiceSucessPage/>}></Route>
        <Route path='/ProjectSucessPage' element={<ProjectSucessPage />}></Route>
        <Route path="/edit/:id" element={<EditClientDetails />} ></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        ):
      (
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/signup1" element={<Signup1 />}></Route>
          <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
          <Route path="/resetpassword" element={<ResetPAsswordForm />}></Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
  {/* <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} /> */}

        {/* <Route path="/dashboard" element={ <PrivateRoute Component={Dashboard} />}></Route>  */}
       
        {/* <Route path='/addproject' element={<AddBussiness/>} ></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path='/invoicePage' element={<Invoice/>}></Route>
        <Route path='/report' element={<Report/>}></Route>
        <Route path='help' element={<Help/>}></Route>
        <Route path='/manageclients' element={<ManageClients/>}></Route>
        <Route path='/clientSucessPage' element={<ClientSucessPage/>}></Route>
        <Route path='/invoiceSucessPage' element={<InvoiceSucessPage/>}></Route>
        <Route path='/ProjectSucessPage' element={<ProjectSucessPage />}></Route>
        <Route path="/edit/:id" element={<EditClientDetails />} ></Route> */}
      



