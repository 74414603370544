import React, { useState } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [email, setEmail] = useState('');
const navigate= useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${apiUrl}/forgot-password`, { email })
      .then(response => {
        console.log(response.data.message);
        Navigate('/resetpassword')
      })
      .catch(error => {
        console.error('Error:', error.response.data.message);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Email:</label>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <button type="submit">Reset Password</button>
    </form>
  );
};

export default ForgotPassword;
