import React, { useState } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

const ResetPAsswordForm = ({ token }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [newPassword, setNewPassword] = useState('');
const navigate= useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${apiUrl}/reset-password`, { token, newPassword })
      .then(response => {
        console.log(response.data.message);
        Navigate('/')
      })
      .catch(error => {
        console.error('Error:', error.response.data.message);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>New Password:</label>
      <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
      <button type="submit">Reset Password</button>
    </form>
  );
};

export default ResetPAsswordForm;
