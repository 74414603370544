function validation(values)
{
    let error = {}
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const password_pattern= /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
    if(values.email === "")
    {
        error.email = "Email should not be empty"
    }
    else if(!email_pattern.test(values.email)){
        error.email ="Please enter the email in correct format"
    }
    else{
        error.email=""
    }
    if(values.password ==="")
    {
    error.password = "Password should not be empty"
    }
    else if(!password_pattern.test(values.password)){
        error.password = "Password pattern is wrong"
    }else{
        error.password=""
    }
    return error;
    
}
export default validation;