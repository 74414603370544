import React, { useEffect, useState } from 'react'
import Header from "./Header";
import style from '../CSS/Style.module.css'
import Footer from "./Footer";
import { Link,useNavigate } from "react-router-dom";
import axios from 'axios';
export default function ManageClients() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [clientData,setClientData]=useState([])
const [editingclient,setEditingclient]=useState({
  id:null,
  client_name:'',
  gst_no:'',
  currency:'',
  email:'',
  phoneno:'',
  addressline1:'',
  addressline2:'',
  city:'',
  zip:'',
  country:'',
})
  // const getClientsData=async ()=>{
  //   const response = await fetch('http://localhost:8500/fetchAllClientsDetails')
  //  const {results}=await response.json()
  //  setClientData(results)
  //  console.log("x",results)
  //  console.log("y",clientData)
  // }
  

  // useEffect(()=>{
  //   getClientsData()
  // },[])
  useEffect(() => {
    fetch(`${apiUrl}/fetchAllClientsDetails`)
      .then((response) => response.json())
      .then((data) =>setClientData(data)
        
      )
      .catch((error) => console.error('Error:', error));
  }, []);

  const handleEdit=(client)=>{
    setEditingclient({
      id:client.id,
    client_name:client.client_name,
    gst_no:client.gst_no,
    currency:client.currency,
    email:client.email,
    phoneno:client.phoneno,
    addressline1:client.addressline1,
    addressline2:client.addressline2,
    city:client.city,
    zip:client.zip,
    country:client.country
  })
  }
const handleUpdate=()=>{
  axios.put(`${apiUrl}/api/clients/${editingclient.id}`,editingclient)
  .then(response=>{
    setClientData(clientData.map(client => (client.id === editingclient.id ? editingclient:client)))
    setEditingclient({id:null,
      client_name:'',
      gst_no:'',
      currency:'',
      email:'',
      phoneno:'',
      addressline1:'',
      addressline2:'',
      city:'',
      zip:'',
      country:'',})
  })
  .catch(error => console.error('Error updating client:', error));
}
  return (
    <><body><Header/>
    <div className={style.main_content}>
        <div className={style.breadcrumps}>
         <ul className={style.breadcrumpsItems} >
          <li>
          <Link to='/dashboard'>Home </Link></li>
          
         <li> <Link to='/manageClients'>Manage Clients</Link></li></ul>
        </div>
        <div className={style.contentwrpper}>

   <h2><center>Manage Clients Page</center></h2>
  <div className='table-container'>
    {
      clientData.length ? (
        <table>
  <thead >
    <tr>
     
      <th >ID</th>
      <th >Client Name</th>
      <th >GST No</th>
      <th >Currency</th>
      <th >Email</th>
      <th >Phone No</th>
      <th >Address Line1</th>
      <th >Addree Line 2</th>
      <th >City</th>
      <th >Zip</th>
      <th >Country</th>
      <th >Action</th>
    </tr>
  </thead>
  <tbody>
    {clientData.map((client)=>(
    <tr key={client.id}>
      
      <td>{client.id}</td>
     
      {/* <td>{editingclient.id === client.id ?(<input type="text" value={editingclient.client_name} onChange={(e)=>setEditingclient({...editingclient,client_name:e.target.value})}/> ):(client.client_name)}</td>
     
      <td>{editingclient.id === client.id ?(<input type="text" value={editingclient.gst_no} onChange={(e)=>setEditingclient({...editingclient,gst_no:e.target.value})}/> ):(client.gst_no)}</td>
     
      <td>{editingclient.id === client.id ?(<input type="text" value={editingclient.currency} onChange={(e)=>setEditingclient({...editingclient,currency:e.target.value})}/> ):(client.currency)}</td>
    
      <td>{editingclient.id === client.id ?(<input type="text" value={editingclient.email} onChange={(e)=>setEditingclient({...editingclient,email:e.target.value})}/> ):(client.email)}</td>
      
      <td>{editingclient.id === client.id ?(<input type="text" value={editingclient.phoneno} onChange={(e)=>setEditingclient({...editingclient,phoneno:e.target.value})}/> ):(client.phoneno)}</td>
      
      <td>{editingclient.id === client.id ?(<input type="text" value={editingclient.addressline1} onChange={(e)=>setEditingclient({...editingclient,addressline1:e.target.value})}/> ):(client.addressline1)}</td>
      
      <td>{editingclient.id === client.id ?(<input type="text" value={editingclient.addressline2} onChange={(e)=>setEditingclient({...editingclient,addressline2:e.target.value})}/> ):(client.addressline2)}</td>
      
      <td>{editingclient.id === client.id ?(<input type="text" value={editingclient.city} onChange={(e)=>setEditingclient({...editingclient,city:e.target.value})}/> ):(client.city)}</td>
      
      <td>{editingclient.id === client.id ?(<input type="text" value={editingclient.zip} onChange={(e)=>setEditingclient({...editingclient,zip:e.target.value})}/> ):(client.zip)}</td>
       
      <td>{editingclient.id === client.id ?(<input type="text" value={editingclient.country} onChange={(e)=>setEditingclient({...editingclient,country:e.target.value})}/> ):(client.country)}</td>
      <td>{editingclient.id == client.id ?(<button onClick={handleUpdate}>Update</button>):
      <button onClick={()=>handleEdit(client)}>Edit</button>}</td> */}
      <td>{client.client_name}</td>
      <td>{client.gst_no}</td>
      <td>{client.currency}</td>
      <td>{client.email}</td>
      <td>{client.phoneno}</td>
      <td>{client.addressline1}</td>
      <td>{client.addressline2}</td>
      <td>{client.city}</td>
      <td>{client.zip}</td>
      <td>{client.country}</td>
     <td><Link to={`/edit/${client.id}`}>Edit</Link></td>
    </tr>))}
  </tbody>
</table>
      ):<p> Can't get client details</p>
    }
  </div>
   </div></div></body><Footer/></>
  )
}
